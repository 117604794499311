import React from "react"
import { Fade } from "react-awesome-reveal"
import Layout from "./Layout"
import Seo from "./Seo"

const isBrowser = typeof window !== "undefined"

const DownloadRedirector = props => {
  const { url, title, type } = props
  const fileType = url.split(".").pop()

  const download = () => {
    if (isBrowser) {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then(response => response.blob())
        .then(blob => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `${title}.${fileType}`)

          // Append to html link element page
          document.body.appendChild(link)

          // Start download
          link.click()

          // Clean up and remove the link
          link.parentNode.removeChild(link)
        })
    }
  }

  download()

  return (
    <div>
      {isBrowser ? (
        <Layout pageType={type}>
          <Seo title="Document downloaded" />
          <Fade triggerOnce>
            <section className="py-6 md:py-12 lg:pb-20">
              <div className="container-fluid">
                <h1 className="mb-6 text-center hero">Document downloaded</h1>
                <h3 className="text-center ">Thanks for your interest in Pod Point</h3>
              </div>
            </section>
            <section className="py-6 border-t md:pt-12 md:pb-20 lg:pt-20 border-grey-10">
              <div className="mx-auto lg:max-w-screen-lg">
                <div className="text-center container-fluid">
                  <h4 className="mb-6 md:mb-10">
                    Download didn't work?
                  </h4>
                  <div
                    className="mb-4 text-white duration-500 ease-in-out bg-green-100 border-green-100 btn hover:bg-white hover:text-green-100"
                    onClick={download}
                    role="button"
                    aria-hidden="true"
                    >
                    <span>Retry now</span>
                  </div>
                </div>
              </div>
            </section>
          </Fade>
        </Layout>
      ) : null}
    </div>
  )
}

export default DownloadRedirector
